import request from '@/utils/request'

export function getHomeInfo() {
  return request({
    url: 'https://shequ.xgzhsq.cn/049linmao/shequ/cms/handler/index.php',
    method: 'get',
    params: {
      'controller': 'outIndxInfo'
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json'
    }
  })
}

export function getLouInfo(params) {
  return request({
    url: 'https://shequ.xgzhsq.cn/049linmao/shequ/cms/handler/index.php',
    method: 'get',
    params: {
      'controller': 'outIndexHuizongInfo',
      'filter[wangge_number]': params.wangge_number,
      'filter[lou_number]': params.lou_number
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json'
    }
  })
}

export function getHuInfo(params) {
  return request({
    url: 'https://shequ.xgzhsq.cn/049linmao/shequ/cms/handler/index.php',
    method: 'get',
    params: {
      'controller': 'outHuInfo',
      'filter[wangge_number]': params.wangge_number,
      'filter[lou_number]': params.lou_number,
      'filter[danyuan_number]': params.danyuan_number
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json'
    }
  })
}

export function getDakaInfo() {
  return request({
    url: 'https://shequ.xgzhsq.cn/049linmao/shequ/cms/handler/index.php',
    method: 'get',
    params: {
      'controller': 'outCheckinLog'
    },
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
      'Accept': 'application/json'
    }
  })
}

export default {
  getLouInfo,
  getHuInfo,
  getHomeInfo,
  getDakaInfo
}
